@import './variables';
@import './mixins';

h1 {
  @include font-weight(extra-bold);
}

.kaercher-button {
  padding: 13px 13px 12px;
  @include font-weight(extra-bold);
  height: auto;
  transition: all 0.25s ease-in-out;
  border: 0.2rem solid transparent;

  &.ant-btn {
    &-primary {
      background: $--color-yellow;
      color: $--color-black-grey;
      border-color: $--color-yellow;

      &:hover {
        @include button-hover;
      }
    }

    &-default {
      color: $--color-white;
      background: #238c96;
      border-color: #238c96;

      &:hover {
        @include button-hover;
      }
    }

    &-ghost {
      color: $--color-black-grey;
      border-color: $--color-black-grey;
      background: transparent;

      &:hover {
        @include button-hover;
      }
    }

    &-action {
      background: $--color-petrol;
      color: $--color-white;
      border-color: $--color-petrol;

      &:hover {
        @include button-hover;
      }
    }
  }

  &[disabled] {
    background: $--color-light-grey;
    color: #b3b3b3;
    border-color: $--color-light-grey;

    &:hover,
    &:active,
    &:focus {
      background: $--color-light-grey;
      color: #b3b3b3;
      border-color: $--color-light-grey;
    }

    //Inverted case for disabled button
    &.inverted {
      background: transparent;
      border-color: #b3b3b3;
    }
  }

  //Inverted cases
  &.inverted {
    &.ant-btn {
      &-primary {
        background: $--color-black-grey;
        color: $--color-white;
        border: solid 2px $--color-black-grey;

        &:hover {
          @include button-hover;
        }
      }

      &-ghost {
        color: $--color-white;
        border: solid 2px $--color-white;
        background: transparent;

        &:hover {
          @include button-hover(true);
        }
      }
    }
  }

  &__size--small {
    font-weight: bold;
    font-size: 13px;
    padding: 7px 13px 4px;
    height: 34px;
  }
}

.break-line {
  height: 10px;

  &__30px {
    height: 30px;
  }

  &__50px {
    height: 50px;
  }
}

.divider {
  &--thin {
    &[class*='ant-divider'] {
      border-top: 1px solid $--color-light-grey;
    }
  }

  &--thick {
    &[class*='ant-divider'] {
      border-top: 2px solid $--color-light-grey;
    }
  }
}

.custom-validate-input {
  &__wrapper {
    position: relative;

    >input {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      outline: none;

      &:focus {
        outline: 0;
        border: solid 1px $--color-processing;
        -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
    }

    &--error {
      >input {
        outline: 0;
        border: solid 1px $--color-dangerous;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }

      &__message {
        position: absolute;
        color: $--color-dangerous;
      }
    }
  }
}

@keyframes bounceIn {

  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-fill-mode: both;
  animation-duration: 1.5s;
  animation-name: bounceIn;
}

.link,
a.link {
  &--underline {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.85);

    &:hover {
      text-decoration: underline;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.fontBold {
  @include font-weight(bold);
}

.ant-table-thead {
  tr {
    th {
      &.ant-table-cell {
        vertical-align: text-top;
        text-align: left;
      }

      span {
        @include font-weight(bold);
      }
    }
  }
}

.container-full-width-page {
  margin: 0 calc(-21% - 5px);
  position: relative;

  @media screen and (max-width: $--ipad--landscape-width) {
    margin: 0 -17px;
    width: calc(100% + 34px);
  }
}

.container-full-width-page-workspace {
  position: relative;
  padding: 0px 0px 0px 10px;
  width: calc(100vw - var(--scrollbar-width));
  left: calc(-50vw + 50%);

  @media screen and (max-width: $--ipad--landscape-width) {
    margin: 0 -17px;
    width: calc(100% + 34px);
    left: inherit;
    padding: 0 20px;
  }
}

.full-width-page-component__wrapper {
  box-sizing: border-box;
  position: relative;
  width: calc(100vw - var(--scrollbar-width));
  left: calc(-50vw + 50%);

  @media screen and (max-width: $--ipad--landscape-width) {
    width: auto;
    left: auto;
  }
}

.full-width {
  width: 100vw;
  margin-inline-start: 50%;
  transform: translateX(-50%);
}

@each $breakpoint, $value in $grid-breakpoints {
  $infix: if($value !=0, $breakpoint, null);

  @if $value {
    @media (min-width: $value) {
      @include generate-spacing($infix);
    }
  }

  @else {
    @include generate-spacing($infix);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  @include animated(0.5s, fadeInUp);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  @include animated(0.5s, fadeInDown);
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  @include animated(0.5s, fadein);
}

.position__relative {
  position: relative;
}

a.link-normalize {
  @include link-normalize;

  &:hover {
    @include link-normalize;
  }
}

.sort-btn {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  position: relative;

  &__icons {
    width: 10px;
    height: 10px;
    font-size: 10px;
    display: inline-block;

    &--down {
      position: absolute;
      left: 0;
      bottom: 5px;
    }

    &--up {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &__texts {
    display: inline-block;
  }
}

.change-view-icons {
  display: inline-block;
  vertical-align: middle;
  padding: 7px 10px 0 10px;
  margin: 5px 0 0 10px;
  border-left: solid 1px $--color-black-grey;

  svg {
    display: inline-block;
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }
  }
}

//Dynamic fade in classes
$durations: '0.1', '0.2', '0.3', '0.4', '0.5', '0.6', '0.7', '0.8', '0.9', '1',
  '2', '3', '4', '5';

@each $duration in $durations {
  .fadeIn-#{str-replace(quote($duration), '.', '')} {
    @include animated(#{unquote($duration)}s, fadein);
  }
}

.empty-content__wrapper {
  min-height: 365px;
  padding-top: 100px;

  @include media-breakpoint-up(md) {
    padding-top: 200px;
  }
}

.kaercher-checkbox {
  .ant-checkbox {
    &-wrapper {
      &:hover {
        .ant-checkbox-inner {
          border-color: $--color-black;
        }
      }
    }

    &-inner {
      border: 2px solid $--color-dark-grey;
      width: 24px;
      height: 24px;
    }

    &-checked {
      .ant-checkbox-inner {
        background-color: $--color-black;
        border: 2px solid $--color-black;

        &::after {
          border-color: $--color-yellow;
          transform: rotate(45deg) scale(1.3) translate(-45%, -55%);
          -moz-transform: rotate(45deg) scale(1.3) translate(-45%, -55%);
          -o-transform: rotate(45deg) scale(1.3) translate(-45%, -55%);
          -webkit-transform: rotate(45deg) scale(1.3) translate(-45%, -55%);
        }

        &::after {
          border-color: $--color-yellow;
        }
      }
    }

    &-input {
      &:focus {
        &+.ant-checkbox-inner {
          border-color: $--color-black;
        }
      }
    }

    & {
      span.ant-checkbox-inner {
        border-color: $--color-black;
      }
    }
  }

  >span:last-child {
    font-weight: 700;
    color: $--color-black-grey;
    align-self: stretch;
    padding-top: 5px;
  }
}

.table-action-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;

  &:hover {
    transform: scale(1.2);
    transition: 0.5s ease;
  }

  >svg {
    display: inline-block;
    vertical-align: middle;
  }
}

.button-as-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.mandatory-title:before {
  display: inline-block;
  margin-inline-end: 4px;
  color: $--color-alarm-error;
  font-size: 14px;
  line-height: 1;
  content: "*";
}

.ant-input[disabled],
.ant-input[disabled]:hover {
  border-color: transparent;
}
